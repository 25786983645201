import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { getToken } from 'utils/token';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
const StyledIframe = styled.iframe`
  height: calc(100vh - 80px)
`;

/**
 * the receiver domain's app should add:
 * window.addEventListener("message", (messageEvent) => {
 *   // console.log("messaged", messageEvent.data)
 *   sessionStorage.setItem("Authorization", messageEvent.data?.Authorization)
 * })
 */
const receiverDomain = 'https://admin.prelios.sit.digitedacademy.net'

const IframePage = (myUserId) => {
  useEffect(() => {
    const iframe = document.getElementById("frame")
    const postMsg = JSON.stringify({ Authorization: getToken().token, UserId: myUserId.myUserId, BackofficeId: myUserId.backOfficeId });
    iframe.addEventListener("load", () => {
      iframe.contentWindow.postMessage(postMsg, receiverDomain);
    })
    document.getElementById("frame").onload = function () {
        setTimeout(function(){
        console.log('timeout 1s');
        iframe.contentWindow.postMessage(postMsg, receiverDomain);
        }, 1000);
    }
  }, []);

  return (
    <StyledIframe
      id='frame'
      src={receiverDomain}
      width='100%'
    />
  );
};

export default connect(
  state => {
    const {
      user: { userId: myUserId }
    } = state.authentication;

    const { backOfficeId } = state.authentication.user.userDomainAttributes;

    return {
      myUserId,
      backOfficeId
    };
  }
)(withMediaQueries(IframePage));