import React from "react";
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  ContentCardUser,
  CustomCarousel,
} from 'ui/components';
import {
  CarouselSettings,
  NewsCarouselSettings,
  CarouselSettingsCourses,
  CarouselSettingsEvents,
  CarouselSettingsPackages
} from "utils/slider-settings";
import { CarouselWrapper } from "./style";
import customCardKnowBe from "../CustomCardKnowBe";

const SectionCarousel = ({
  data,
  type,
  onClick,
  // HOC, media queries
  currentViewport,
  viewportTypes,
}) => {
  let settings = CarouselSettings;

  switch (type) {
    case 'news':
      settings = NewsCarouselSettings;
      break;
    
    case 'course':
      settings = CarouselSettingsCourses;
      break;

    case 'live_event':
      settings = CarouselSettingsEvents;
      break;

    case 'package':
      settings = CarouselSettingsPackages;
      break;
  
    default:
      break;
  }

  return (
    <CarouselWrapper
      className={viewportTypes[currentViewport] >= viewportTypes.mediaIsLaptop ? type : ''}
    >
      <CustomCarousel
        isSDS
        config={settings}
      >
        {data?.length > 0 &&
        data.map(card => (
          <ContentCardUser
            homepage
            key={card.itemId ?? card.packageId}
            type={type === 'card' ? card.type : type}
            data={card}
            onClick={() => onClick(card)}
          />
        ))}
      </CustomCarousel>
    </CarouselWrapper>
  );
};

SectionCarousel.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  // HOC, media queries
  currentViewport: PropTypes.string.isRequired,
  viewportTypes: PropTypes.shape({
    IsPhone: PropTypes.oneOf([0]),
    IsTablet: PropTypes.oneOf([1]),
    IsLaptop: PropTypes.oneOf([2]),
    IsDesktop: PropTypes.oneOf([3]),
    IsLargeDesktop: PropTypes.oneOf([4])
  }),
};

export default withMediaQueries(SectionCarousel);
