import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import { Footer, Grid } from 'ui/components';
import { push } from 'redux-first-history';
import ProDisplay from 'ui/typography/proDisplay';
import routes from 'routes';
import { GET_CATEGORY_PLAYLIST, GET_PATH, GET_CATEGORIES } from 'redux/actions/playlist';
import { detailType } from 'utils/common';
import { Head, IconBack, Title, Text, BtnContainer, LockedEvents } from './style';
import MainContainer from '../style';
import { PlaylistTypes } from 'const';
import { Button } from 'ui/atoms';
import { GET_COLLECTIONS, GET_SELECTIONS, GET_RECOMMENDED_COLLECTIONS, GET_ALL_EVENTS, GET_EVENTS_LOCKED } from 'redux/actions/playlist';
import { getFirstLastDayOfMonth } from 'utils/date-format';
import customCardKnowBe from "../../ui/components/Homepage/CustomCardKnowBe";

const AllPlaylists = ({
  pushUrl,
  getMyPlaylists,
  collections = [],
  selections,
  playlistSelections,
  playlistCollections,
  playlistRecommendedCollections,
  playlistPlannedEvents,
  playlistLockedEvents,
  playlistCategories,
  categoriesPills,
  filterId,
  name,
  filteredCollections,
  type,
  selectionsNext,
  collectionsNext,
  recommendedCollectionsNext,
  categoryPlaylistNext,
  getAllEvents,
  getEventsLocked,
  getCategories
}) => {
  const [playlists, setPlaylists] = useState([]);
  const [playlistsLocked, setPlaylistLockedEvents] = useState([]);
  const [buttonVisible, setbuttonVisible] = useState(false);
  const [categoryPlaylistId, setCategoryPlaylistId] = useState('');

  useEffect(() => {
    if(!categoryPlaylistId && Boolean(categoriesPills)) {
    const categoryPlaylistId = categoriesPills[0]?.needId;
    setCategoryPlaylistId(categoryPlaylistId)
    }
  }, [categoriesPills]);

  const dispatch = useDispatch();
  const getNextSelections = useCallback(() => dispatch({ type: GET_SELECTIONS._REQUEST }), [dispatch]);
  const getNextCollections = useCallback(() => dispatch({ type: GET_COLLECTIONS._REQUEST }), [dispatch]);
  const getNextRecommendedCollections = useCallback(() => dispatch({ type: GET_RECOMMENDED_COLLECTIONS._REQUEST }), [dispatch]);
  const getNextCategoryList = useCallback((categoryPlaylistId) => dispatch({ type: GET_CATEGORY_PLAYLIST._REQUEST, filterId: categoryPlaylistId }), [dispatch]);
  
  useEffect(() => {
    if (!filterId && !type) {
      // getAllCollections();
      getMyPlaylists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterId]);

  useEffect(() => {
    if(type === PlaylistTypes.EVENTS) {
      const { firstDay } = getFirstLastDayOfMonth();
      getAllEvents(new Date().getTime());
      getEventsLocked({
        startDate: new Date().getTime()
      });
    }
  },[getAllEvents, getEventsLocked]);

  useEffect(() => {
    if (!!filterId) {
      getCategories(filterId);
    }
    
  },[getCategories, filterId]);

  useEffect(() => {
    if (Boolean(type)) {
      switch (type) {
        case PlaylistTypes.SELECTIONS:
          // aggiungo card custom statica in formazione obbligatoria
          setPlaylists([customCardKnowBe, ...playlistSelections]);
          setbuttonVisible(selectionsNext);
          break;

        case PlaylistTypes.COLLECTIONS:
          setPlaylists(playlistCollections);
          setbuttonVisible(collectionsNext);
          break;

        case PlaylistTypes.RECOMMENDED_COLLECTIONS:
          setPlaylists(playlistRecommendedCollections);
          setbuttonVisible(recommendedCollectionsNext);
          break;
        
        case PlaylistTypes.CATEGORIES:
          setPlaylists(playlistCategories);
          setbuttonVisible(categoryPlaylistNext);
          break;

        case PlaylistTypes.EVENTS:
          setPlaylists(playlistPlannedEvents);
          setPlaylistLockedEvents(playlistLockedEvents);
          break;

        default:
          break;
      }
    } else {
      if (filterId && filterId > 0) {
        setPlaylists(filteredCollections);
      } else {
        setPlaylists([...collections, ...selections]);
      }
    }
  }, [collections, selections, filteredCollections, filterId, playlistSelections, playlistCollections, playlistRecommendedCollections, type, selectionsNext, collectionsNext, recommendedCollectionsNext, categoryPlaylistNext, playlistCategories, playlistPlannedEvents, playlistLockedEvents]);

  function getNextPlaylist(type) {
    switch (type) {
      case PlaylistTypes.SELECTIONS:
        getNextSelections();
        break;

      case PlaylistTypes.COLLECTIONS:
        getNextCollections();
        break;

      case PlaylistTypes.RECOMMENDED_COLLECTIONS:
        getNextRecommendedCollections();
        break;
      
      case PlaylistTypes.CATEGORIES:
        getNextCategoryList(categoryPlaylistId);
        break;

      default:
        break;
    }
  }

  function getPageTitle(type) {
    switch (type) {
      case PlaylistTypes.SELECTIONS:
        return {
          title: "Formazione obbligatoria",
          subtitle: "Qui trovi tutti i corsi da completare per soddisfare i requisiti di formazione del tuo programma"
        }

      case PlaylistTypes.COLLECTIONS:
        return {
          title: "Formazione Complementare",
          subtitle: "Per ampliare le tue conoscenze, per sviluppare competenze e conoscenze complementari. Pensati per te, che vuoi andare “oltre”"
        }

      case PlaylistTypes.RECOMMENDED_COLLECTIONS:
        return {
          title: "Suggeriti per te",
          subtitle: "Non accontentarti! Esplora ulteriori contenuti formativi scelti per te"
        }

      case PlaylistTypes.EVENTS:
        return {
          title: "Eventi pianificati",
          subtitle: "Qui puoi trovare tutti gli eventi live presrenti nel tuo Piano Formativo"
        }

      case PlaylistTypes.CATEGORIES:
        const categoryTitle = categoriesPills.find(pill => playlistCategories.find(category => category?.needId === pill?.needId))?.title;

        return {
          title: categoryTitle,
        }
    
      default:
        break;
    }
  }

  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <IconBack
              className="icon-arrow-right"
              onClick={() => pushUrl(routes.homepage.path)}
            />
            <Title>
              <ProDisplay type="pageTitle">
                { name ? name : getPageTitle(type)?.title }
              </ProDisplay>
              <Text>
                <ProDisplay
                  type="tableLabel"
                  configuration={{ color: 'placeholder' }}>
                  { type ? getPageTitle(type)?.subtitle : null }
                </ProDisplay>
              </Text>
            </Title>
          </Head>
          {playlists?.length > 0 ? (
            <>
              <Grid
                elements={playlists}
                location={type === PlaylistTypes.EVENTS ? detailType.LIVE_EVENT : detailType.PLAYLIST}
                section="playlist"
              />
            { buttonVisible && <BtnContainer>
              <Button
                text="Mostra altri"
                type="PRIMARY"
                height={35}
                fontSize={16}
                onClick={() => getNextPlaylist(type)}
              />
            </BtnContainer> }
            </>
          ) : (
            <Text>
              <ProDisplay type="1">
              {type === PlaylistTypes.EVENTS ? 'Non sono presenti nuovi eventi pianificati nel tuo Piano Formativo' : 'Non ci sono contenuti formativi.'}
              </ProDisplay>
            </Text>
          )}

        {type === PlaylistTypes.EVENTS && <LockedEvents>
            <>
            <Head>
              <Title>
                <ProDisplay type="pageTitle">
                  Eventi disponibili
                </ProDisplay>
                <Text>
                  <ProDisplay
                    type="tableLabel"
                    configuration={{ color: 'placeholder' }}>
                    Qui puoi trovare tutti gli eventi live organizzati e disponibili per l'iscrizione
                  </ProDisplay>
                </Text>
              </Title>
            </Head>
            {playlistsLocked?.length > 0 ? (
              <>
                <Grid
                  elements={playlistsLocked}
                  location={detailType.LIVE_EVENT_LOCKED}
                  section="playlist"
                />
              { buttonVisible && <BtnContainer>
                <Button
                  text="Mostra altri"
                  type="PRIMARY"
                  height={35}
                  fontSize={16}
                  onClick={() => getNextPlaylist(type)}
                />
              </BtnContainer> }
              </>
            ) : (
              <Text>
                <ProDisplay type="1">
                  Non ci sono eventi disponibili.
                </ProDisplay>
              </Text>
            )}
          </>
        </LockedEvents>}
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

AllPlaylists.propTypes = {
  // HOC (connect, state)
  collections: PropTypes.array,
  selections: PropTypes.array,
  playlistCollections: PropTypes.array,
  playlistSelections: PropTypes.array,
  playlistRecommendedCollections: PropTypes.array,
  playlistLockedEvents: PropTypes.array,
  playlistCategories: PropTypes.array,
  categoriesPills: PropTypes.array,
  filterId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]
  ),
  name: PropTypes.string,
  filteredCollections: PropTypes.array,
  selectionsNext: PropTypes.bool,
  collectionsNext: PropTypes.bool,
  recommendedCollectionsNext: PropTypes.bool,
  categoryPlaylistNext: PropTypes.bool,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func,
  // getAllCollections: PropTypes.func,
  getMyPlaylists: PropTypes.func
};

export default withMediaQueries(
  connect(
    state => {
      const {
        selection: { items: selections },
        collection: { items: collections }
      } = state.playlist.playlists.yourpathprogress;
      // const { allCollections: collections } = state.playlist.playlists;

      const playlistSelections = state.playlist.selections.items;
      const playlistCollections = state.playlist.collections.items;
      const playlistRecommendedCollections = state.playlist.recommendedCollections.items;
      const playlistPlannedEvents = state.playlist.events.allEvents;
      const playlistLockedEvents = state.playlist.events.lockedEvents;
      const playlistCategories = state.playlist.categoryPlaylist.items;
      const categoriesPills = state.playlist.categoriesPills;

      const selectionsNext = Boolean(state.playlist.selections.nextPage);
      const collectionsNext = Boolean(state.playlist.collections.nextPage);
      const recommendedCollectionsNext = Boolean(state.playlist.recommendedCollections.nextPage);
      const categoryPlaylistNext = Boolean(state.playlist.categoryPlaylist.nextPage);

      const { filterId, name, filteredCollections } = state.playlist.filters;
      return {
        selections,
        collections,
        filterId,
        name,
        filteredCollections,
        playlistSelections,
        playlistCollections,
        playlistRecommendedCollections,
        playlistPlannedEvents,
        playlistLockedEvents,
        playlistCategories,
        selectionsNext,
        collectionsNext,
        recommendedCollectionsNext,
        categoriesPills,
        categoryPlaylistNext
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      getCategories: filterId => dispatch({type: GET_CATEGORIES._REQUEST, filterId}),
      getAllEvents: startDate => dispatch({type: GET_ALL_EVENTS._REQUEST, startDate}),
      getEventsLocked: startDate => dispatch({type: GET_EVENTS_LOCKED._REQUEST, startDate}),
      getMyPlaylists: () =>
        dispatch({
          type: GET_PATH._REQUEST
        })
    })
  )(AllPlaylists)
);
